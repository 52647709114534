





































import {Component, Prop, Vue} from 'vue-property-decorator';
import {PageResultDto} from '@/framework/models/ResultDto';
import {
  MemberShipAssessmentsDto,
  GetListMemberShipAssessmentsDto
} from '@/framework/models/MemberShipAssessments';
import MemberShipAssessmentsApi from '@/api/MemberShipAssessments';

@Component({})
export default class AssessmentInfo extends Vue {
  private api = new MemberShipAssessmentsApi();
  private listQuery = new GetListMemberShipAssessmentsDto();
  private tableData: PageResultDto<MemberShipAssessmentsDto> = {
    items: new Array<MemberShipAssessmentsDto>(),
    totalCount: 0
  };

  private async activated() {
    this.listQuery.isFinish = true
    await this.getList();
  }

  private async getList() {
    this.tableData = await this.api.getListAsync(this.listQuery);
  }
}
